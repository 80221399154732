if (document.getElementById('upload_csv_parent')) {
  import(/* webpackChunkName: "csv_uploader" */ './modules/csv-uploader').then(({ default: csv_uploader }) => {
    csv_uploader.init();
  }).catch(() => 'An error occurred while loading the component csv_uploader');
}
if (document.getElementById('upload_kmz_parent')) {
  import(/* webpackChunkName: "kmz_uploader" */ './modules/kmz-uploader').then(({ default: kmz_uploader }) => {
    kmz_uploader.init();
  }).catch(() => 'An error occurred while loading the component kmz_uploader');
}
if (document.querySelector('#goddessForm')) {
  import('./modules/goddess');
}
if (document.querySelector('#mattermost-account-levels')) {
  import('./modules/mattermost-account-levels');
}

setTimeout(() => {
  if (document.getElementById('bug-form')) {
    import('./modules/bug-backend');
  }
}, 1000);
